<template>
    <div class="grid">
        <div class="col-12 md:col-8">
            <SelectButton v-model="byHandle" :options="multiToggle" optionLabel="name" optionValue="value" @change="toggleMulti" aria-labelledby="single" />
        </div>
        <div class="col-12 md:col-4 text-right">
            <Button label="Add New Webhook" class="p-button-text" @click="showInstructions" />
        </div>
    </div>
    <div class="col-12">
        <div class="card">
            <DataTable :value="donations" :lazy="true" :paginator="true" :rows="2000" :totalRecords="totalRecords"
                :loading="loading" @page="onPage($event)" @sort="onSort($event)" @filter="onFilter($event)"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink" sortMode="multiple"
                removableSort responsiveLayout="scroll" :scrollable="true" scrollHeight="600px"
                v-model:filters="filters" filterDisplay="menu" :first="firstRow" ref="dt" :key="refreshKey">
                <!-- <template #header> -->
            <!-- <div class="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined" @click="clearFilter()"/>
                <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                    <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                </span>
            </div>
        </template> -->
                <template #empty>
                    No donations found.
                </template>
                <template #loading>
                    Loading donation data...
                </template>
                <Column v-if="!byHandle" field="paidAt" filterField="paidAt" header="Paid At" :sortable="true"
                    style="min-width:120px;">
                    <!-- <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                            placeholder="Search paid at" />
                    </template> -->
                </Column>
                <Column v-if="byHandle" field="mostRecentContribution" filterField="mostRecentContribution" header="MRC" :sortable="true"
                    style="min-width:120px;">
                    <!-- <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                            placeholder="Search paid at" />
                    </template> -->
                </Column>
                <Column  v-if="!byHandle" field="managingEntityName" filterField="managingEntityName" header="Page Name" :sortable="true"
                    style="min-width:150px;">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                            placeholder="Search page name" />
                    </template>
                </Column>
                <Column  v-if="byHandle" field="managingEntityName" filterField="managingEntityName" header="Page Names" :sortable="true"
                    style="min-width:150px;">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                            placeholder="Search page names" />
                    </template>
                </Column>
                <Column field="refcode2" filterField="refcode2" header="Refcode 2" :sortable="true"
                    style="min-width:160px;">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                            placeholder="Search refcode2" />
                    </template>
                </Column>
                <Column v-if="!byHandle" field="amount" filterField="amount" header="Amount" :sortable="true" 
                    style="min-width:120px;" dataType="numeric">
                    <template #filter="{filterModel}">
                        <InputNumber v-model="filterModel.value" mode="currency" currency="USD" locale="en-US" placeholder="Search amount" />
                    </template>
                </Column>
                <Column v-if="byHandle" field="countOrgs" filterField="countOrgs" header="# Of Orgs" :sortable="true" 
                    style="min-width:120px;" dataType="numeric">
                    <template #filter="{filterModel}">
                        <InputNumber v-model="filterModel.value" placeholder="Search # of orgs" />
                    </template>
                </Column>
                <Column v-if="byHandle" field="totalDonated" filterField="totalDonated" header="Total Donated" :sortable="true" 
                    style="min-width:120px;" dataType="numeric">
                    <template #filter="{filterModel}">
                        <InputNumber v-model="filterModel.value" mode="currency" currency="USD" locale="en-US" placeholder="Search total donated" />
                    </template>
                </Column>
                <Column v-if="byHandle" field="timesDonated" filterField="timesDonated" header="Times Donated" :sortable="true" 
                    style="min-width:120px;" dataType="numeric">
                    <template #filter="{filterModel}">
                        <InputNumber v-model="filterModel.value" placeholder="Search times donated" />
                    </template>
                </Column>
                <Column field="firstname" filterField="firstname" header="First Name" :sortable="true"
                    style="min-width:120px;">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                            placeholder="Search first name" />
                    </template>
                </Column>
                <Column field="lastname" filterField="lastname" header="Last Name" :sortable="true"
                    style="min-width:120px;">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                            placeholder="Search last name" />
                    </template>
                </Column>
                <Column field="city" filterField="city" header="City" :sortable="true"
                    style="min-width:120px;">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                            placeholder="Search city" />
                    </template>
                </Column>
                <Column field="state" filterField="state" header="State" :sortable="true"
                    style="min-width:100px;">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                            placeholder="Search state" />
                    </template>
                </Column>
                <Column field="zip" filterField="zip" header="Zip" :sortable="true"
                    style="min-width:100px;">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                            placeholder="Search zip" />
                    </template>
                </Column>
                <Column field="phone" filterField="phone" header="Phone" :sortable="true"
                    style="min-width:120px;">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                            placeholder="Search phone number" />
                    </template>
                </Column>
                <Column field="email" filterField="email" header="Email" :sortable="true"
                    style="min-width:250px;">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                            placeholder="Search email" />
                    </template>
                </Column>
            </DataTable>
            <Button icon="pi pi-external-link" style="float: right;" label="Export Table" class="mt-5" @click="exportCSV($event)" />
        </div>
    </div>
    <Dialog header="Add a new webhook" v-model:visible="webhookModalShow" :dismissableMask="true" :modal="true" style="width: 550px;" >
        <p>Adding data to the Insight DB is as simple as setting up three webhooks.<br /><br /> Within ActBlue, go to Integrations, then Webhooks, and then request three new webhooks (ActBlue Default, ActBlue Default Cancellations, and ActBlue Refunds) for each client you want added to the database. Use the following information when requesting the webhooks:</p>
        <p>
            <span @click="copyThis('connect@optakit.com')">Contact Email: connect@optakit.com</span><br />
            <span @click="copyThis('https://us-east4-opta-momentum.cloudfunctions.net/receiver')">Endpoint URL: https://us-east4-opta-momentum.cloudfunctions.net/receiver</span><br />
            <span @click="copyThis('insight_db')">Username: insight_db</span><br />
            <span @click="copyThis('geh8vkz_XKA_gmd.cqx')">Password: geh8vkz_XKA_gmd.cqx</span><br />
            <span @click="copyThis('01/01/2020')">Backfill Date: The date you want information backfilled to (we recommend 01/01/2020). </span>
            <br />  
        </p>
              
    </Dialog>
        
</template>

<script>
    import { FilterMatchMode, FilterOperator } from 'primevue/api';
    import {fireauth} from '@/firebase';
    import { copyText } from 'vue3-clipboard';

    export default {
        data() {
            return {
                loading: true,
                donations: [],
                content: [],
                filters: null,
                firstRow: 0,
                multiSortMeta: {},
                lazyParams: {},
                multiToggle: [
                    {"name": "By Donation", "value": false },
                    {"name": "By Handle", "value": true}
                ],
                byHandle: false,
                totalRecords: 0,
                refreshKey: 0,
                webhookModalShow: false,
            }
        },
        methods: {
            exportCSV() {
                this.$refs.dt.exportCSV();
            },
            async toggleMulti() {
                this.multiSortMeta = {};
                this.lazyParams = {};
                this.firstRow = 0;
                this.refreshKey += 1;
                
                // console.log(this.refreshKey)
                this.clearFilter();
                await this.getDonations();
            },
            getDonations() {
                this.donations = [];
                this.loading = true;
                let authorizationBasic = window.btoa("pineapple:" +  fireauth.currentUser.uid);
                //SEND HTTP REQUEST
                var self = this
                var request = new XMLHttpRequest();
                var url = "https://momentum-data-qydbcapgha-uk.a.run.app";
                request.open("POST", url, true);
                request.setRequestHeader("Content-Type", "application/json");
                request.setRequestHeader('Authorization', 'Basic ' + authorizationBasic);
                request.onreadystatechange = function () {
                    if (request.readyState === 4 && request.status === 200) {
                        var data = JSON.parse(request.responseText);
                        // console.log("returned data", data);
                        if (data.length > 0) self.totalRecords = data[0]["totalRecords"];
                        else self.totalRecords = 0;
                        for (let row of data) {
                            self.donations.push(row);
                        }
                        self.loading = false;
                    }
                };
                var data = JSON.stringify(
                    {
                        "table_params": this.lazyParams,
                        "requested_data": this.byHandle ? "clientsTableByTwitterHandle" : "clientsTableByDonation",
                    });
                request.send(data);
                // console.log(data)
            },
            initFilters() {
                this.filters = {
                    //'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                    'refcode2': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'managingEntityName': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'city': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'state': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'zip': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'amount': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                    'totalDonated': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                    'timesDonated': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                    'countOrgs': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                    'email': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'firstname': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'lastname': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'paidAt': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                    'phone': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                }
            },
            clearFilter() {
                this.initFilters();
                this.firstRow = 0;
                this.initLazyParams();
                // this.getRevenue();
            },
            formatCurrency(value) {
                return Number(value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            },
            formatImpression(value) {
                return Number(value).toLocaleString('en-US');
            },
            formatDate(value) {
                value = new Date(value + " EDT")
                return value.toLocaleString('en-US', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                });
            },
            onPage(event) {
                this.firstRow = event.first;
                this.initLazyParams();
            },
            onSort(event) {
                this.multiSortMeta = event.multiSortMeta;
                this.initLazyParams();
                console.log("multisort", this.multiSortMeta)
            },
            onFilter() {
                this.firstRow = 0;
                this.initLazyParams();
            },
            initLazyParams() {
                this.lazyParams = {
                    first: this.firstRow,
                    rows: this.$refs.dt.rows,
                    multiSortMeta: this.multiSortMeta,
                    filters: this.filters
                };
                this.getDonations();
            },
            showInstructions() {
                this.webhookModalShow = true;
            },
            copyThis(text) {
                copyText(text, undefined, (error) => {
                    if (error) {
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Could not copy', life: 1000 });
                        console.log(error);
                    } else {
                        this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Copied!', life: 1500 });
                    }
                })
            },
        },
        mounted() {
            this.getDonations();
        },
        created() {
            this.initFilters();
        }
    }
</script>

<style scoped lang="scss">
</style>